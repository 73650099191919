import useStorageEvent from '@/hooks/useStorageEvent';
import { loggedIn } from '@/utils/auth';
import { useState } from 'react';

export const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn());

  useStorageEvent('refresh_token', () => {
    setIsLoggedIn(loggedIn());
  });

  return { isLoggedIn };
};
