import { Permissions } from '@/domain/permission';
import NotAllowedPage from '@/pages/403';
import { lazy } from 'react';

const AuthCallbackPage = lazy(() => import('@/pages/auth/callback'));
const SecurityLayout = lazy(() => import('@/components/SecurityLayout'));
const Layout = lazy(() => import('@/components/common/Layout'));
const AccountPage = lazy(() => import('@/pages/account'));
const BidPage = lazy(() => import('@/pages/bids'));
const CalendarPage = lazy(() => import('@/pages/calendar'));
const DashboardPage = lazy(() => import('@/pages/dashboard'));
const InsurancePage = lazy(() => import('@/pages/insurance'));
const JobsSingle = lazy(() => import('@/pages/jobs/single'));
const RegistrationPage = lazy(() => import('@/pages/registration'));
const SettingsPage = lazy(() => import('@/pages/settings'));
const ServiceRegionsPage = lazy(() => import('@/pages/settings/service-regions'));
const UsersPage = lazy(() => import('@/pages/settings/users'));
const ManageUser = lazy(() => import('@/pages/settings/users/manage'));
const BidsPage = lazy(() => import('@/pages/work-orders/bids'));
const CompletedWorkOrdersPage = lazy(() => import('@/pages/work-orders/completed'));
const OpenWorkOrdersPage = lazy(() => import('@/pages/work-orders/open'));
const ProfileWorkOrdersPage = lazy(() => import('@/pages/work-orders/profile'));
const InvoicesPage = lazy(() => import('@/pages/invoices'));
const InvoiceDetails = lazy(() => import('@/pages/invoices/details'));
const RolesAndPermissions = lazy(() => import('@/pages/settings/rolesAndPermissions'));
const ServicesProvided = lazy(() => import('@/pages/settings/services-provided'));

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  permissions?: Permissions[];
  props?: Record<string, unknown>;
};

export const routes: PropifyRoute[] = [
  {
    path: '/auth',
    routes: [
      {
        path: '/auth/callback',
        component: AuthCallbackPage,
      },
    ],
  },
  {
    path: '/registration',
    component: RegistrationPage,
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: Layout,
        routes: [
          {
            path: '/',
            redirect: '/dashboard',
          },

          {
            path: '/dashboard',
            component: DashboardPage,
          },
          {
            path: '/account',
            component: AccountPage,
          },
          {
            path: '/calendar',
            component: CalendarPage,
          },
          {
            path: '/settings/services-provided',
            component: ServicesProvided,
            permissions: [Permissions.READ_SERVICE],
          },
          {
            path: '/settings',
            component: SettingsPage,
          },
          {
            path: '/users',
            component: UsersPage,
            permissions: [Permissions.READ_USER],
          },
          {
            path: '/service-regions',
            component: ServiceRegionsPage,
            permissions: [Permissions.READ_SERVICE_REGION],
          },
          {
            path: '/user/manage/:id',
            component: ManageUser,
            permissions: [Permissions.UPDATE_USER],
          },
          {
            path: '/jobs/single',
            component: JobsSingle,
            permissions: [Permissions.READ_JOB],
          },
          {
            path: '/work-orders/completed',
            component: CompletedWorkOrdersPage,
            permissions: [Permissions.READ_WORK_ORDER],
          },
          {
            path: '/work-orders/open',
            component: OpenWorkOrdersPage,
            permissions: [Permissions.READ_WORK_ORDER],
          },
          {
            path: '/work-orders/bids/:filter',
            component: BidsPage,
          },
          {
            path: '/work-orders/bids',
            component: BidsPage,
          },
          {
            path: '/work-orders/:id',
            component: ProfileWorkOrdersPage,
            permissions: [Permissions.READ_WORK_ORDER],
          },
          {
            path: '/roles-and-permissions',
            component: RolesAndPermissions,
          },
          {
            path: '/insurance',
            component: InsurancePage,
            permissions: [Permissions.READ_INSURANCE],
          },
          {
            path: '/invoices',
            component: InvoicesPage,
          },
          {
            path: '/invoices/:id',
            component: InvoiceDetails,
          },
          {
            path: '/bids/:id',
            component: BidPage,
            permissions: [Permissions.READ_WORK_ORDER],
          },
        ],
      },
    ],
  },
];

export const unauthorizedRoute: Omit<PropifyRoute, 'path'> = {
  component: NotAllowedPage,
};
