import UnexpectedErrorSvg from '@/assets/403.svg?react';
import { Colors } from '@propify/components';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, makeStyles, Typography } from '../utils/material';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginBottom: '12px',
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.disabled,
    marginBottom: '32px',
  },
});

const NoFoundPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackHome = () => navigate('/');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
      <UnexpectedErrorSvg />
      <Box flexDirection="column" alignItems="center" mt={3}>
        <Typography variant="h5" className={classes.title}>
          403
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          You are not allowed to visit this page.
        </Typography>
        <Box textAlign="center">
          <Button color="primary" onClick={handleBackHome}>
            Back Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoFoundPage;
