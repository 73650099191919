import { getAccessToken, getInviteToken, getRefreshToken, logout, parseJwt } from '@/utils/auth';
import { api } from '@/utils/configuration';
import { handleError } from '@/utils/utils';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';
import type { LoginResponse } from './auth';
import { AuthService } from './auth';

const excludedRoutesFromAuth: string[] = ['/registration'];

export const canContinueWithoutAuth = () => {
  return excludedRoutesFromAuth.includes(window.location.pathname);
};

const JWT_EXPIRY_THRESHOLD = 5000;
const PUBLIC_ENDPOINTS = [
  `${api.AUTH_URL}/refresh`,
  `${api.AUTH_URL}/register-org`,
  `${api.AUTH_URL}/login`,
  '/enumerations/state',
];
let refreshPromise: Promise<LoginResponse> | undefined;

const apiClient = Axios.create({
  baseURL: '/rest',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: {
    indexes: null,
  },
});

const isJwtGood = (jwt: string | null) => {
  const parsed = parseJwt(jwt);

  if (!parsed) {
    return false;
  }

  return jwt && Date.now() < parsed.exp * 1000 - JWT_EXPIRY_THRESHOLD;
};

apiClient.interceptors.request.use((config) => {
  // No extra configuration for absolute URLs (i.e.: a file in S3)
  if (config.url!.charAt(0) !== '/') {
    return config;
  }

  if (PUBLIC_ENDPOINTS.includes(config.url || '')) {
    return config;
  }

  const accessToken = getAccessToken();
  if (!isJwtGood(accessToken)) {
    if (canContinueWithoutAuth()) {
      let inviteToken = getInviteToken();
      if (!inviteToken) {
        inviteToken = new URLSearchParams(location.search).get('jwt');
      }

      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${inviteToken}`;
      return config;
    }

    const refreshToken = getRefreshToken();
    if (!isJwtGood(refreshToken)) {
      logout();
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Axios.Cancel('The access and refresh tokens are both missing or expired');
    }

    refreshPromise = refreshPromise || AuthService.refreshToken({ refreshToken: refreshToken! });
    return refreshPromise
      .then((response) => {
        refreshPromise = undefined;
        config.headers = config.headers ?? {};
        config.headers.Authorization = `Bearer ${response.accessToken}`;
        return config;
      })
      .catch((error) => {
        logout();
        handleError(error, { displayToast: false });
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new Axios.Cancel('Error communicating with the authorization system.');
      });
  }
  config.headers = config.headers ?? {};
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && !canContinueWithoutAuth()) {
      logout();
      return;
    }

    throw error;
  },
);

export const crudService = {
  get: <T>(url: string, params?: AxiosRequestConfig) =>
    apiClient.get<T, AxiosResponse<T>>(url, params).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: <T>(obj: any, url: string, params?: AxiosRequestConfig) =>
    apiClient.post<T, AxiosResponse<T>>(url, obj, params).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: <T>(obj: any, url: string) =>
    apiClient.put<T, AxiosResponse<T>>(url, obj).then((response) => response.data),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch: <T>(obj: any, url: string) =>
    apiClient.patch<T, AxiosResponse<T>>(url, obj).then((response) => response.data),

  delete: <T>(url: string) =>
    apiClient.delete<T, AxiosResponse<T>>(url).then((response) => response.data),
};

export type UseFetchConfiguration<
  Data,
  Error,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KeyParameters extends any[],
  Fn extends (...args: KeyParameters) => Promise<Data>,
> = SWRConfiguration<Data, Error> & {
  fetcher?: Fn;
  errorMessage?: string;
};

export type UseFetchResponse<Data, Error> = SWRResponse<Data, Error>;

export const useFetch = <
  Data,
  Error,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FetchParameters extends any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KeyParameters extends any[],
>(
  config: UseFetchConfiguration<
    Data,
    Error,
    KeyParameters,
    Fetcher<Data, FetchParameters, KeyParameters>
  >,
  ...params: KeyParameters
): UseFetchResponse<Data, Error> => {
  const newKey = config.fetcher?.key ? config.fetcher.key(...params) : null;
  const jsonNewKey = JSON.stringify(newKey);

  const [key, setKey] = useState<typeof newKey>(null);
  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    setKey(newKey);
  }, [jsonNewKey]);

  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
    };
  }, []);

  const fetcher = useCallback(
    (args: FetchParameters) => {
      const newController = new AbortController();
      controllerRef.current?.abort();
      controllerRef.current = newController;

      return config
        .fetcher!.fetch(args, newController.signal)
        .then((data) => {
          // Check if aborted and throw an error to avoid caching invalid data
          if (newController.signal.aborted) {
            throw new Error('Fetch Aborted');
          }
          return data;
        })
        .catch((error) => {
          if (newController.signal.aborted) {
            throw error;
          }
          handleError(error, {
            displayToast: !!config?.errorMessage,
            toastMessage: config?.errorMessage,
            rethrowError: true,
          });
          throw error;
        });
    },
    [config.errorMessage, config.fetcher],
  );

  return useSWR(key, {
    revalidateOnFocus: false,
    ...config,
    fetcher,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface Fetcher<Result, FetchParameters extends any[], KeyParameters extends any[]> {
  (...args: KeyParameters): Promise<Result>;
  fetch: (args: FetchParameters, signal?: AbortSignal) => Promise<Result>;
  key: (...args: KeyParameters) => FetchParameters;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AxiosFetcher<Result, KeyParameters extends any[]> = Fetcher<
  Result,
  Parameters<typeof crudService.get>,
  KeyParameters
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAxiosFetcher = <Result, KeyParameters extends any[], ReturnType = Result>(
  key: AxiosFetcher<Result, KeyParameters>['key'],
  postProcessor?: (result: Promise<Result>) => Promise<ReturnType>,
) => {
  const fetcher: AxiosFetcher<Result | ReturnType, KeyParameters> = (...args: KeyParameters) =>
    fetcher.fetch(key(...args));

  fetcher.fetch = (...[args, signal]: Parameters<AxiosFetcher<Result, KeyParameters>['fetch']>) => {
    const [url, params] = args;
    const queryResult = crudService.get<Result>(url, {
      ...params,
      signal: params?.signal ?? signal,
    });
    return postProcessor ? postProcessor(queryResult) : queryResult;
  };

  fetcher.key = (...args: KeyParameters) => {
    const r = key(...args);
    const params = r[1];

    if (params !== undefined && Object.entries(params).length === 0) {
      r.pop();
    }

    return r;
  };

  return fetcher;
};
