import type { User } from '@/domain/user';
import { api } from '@/utils/configuration';
import type { Operation } from 'fast-json-patch';
import { crudService, getAxiosFetcher } from './apiClient';
import LookupService from './lookup';

export type UserSearchParams = {
  keywords?: string;
  status?: string;
  roleIds?: string[];
  roleId?: string;
};

export interface Invitation {
  emailAddress: string;
  userRoleTypeIds: number[];
}

export interface UpdatePasswordBody {
  originalPassword: string;
  password: string;
}

export interface UpdateProfileBody {
  firstName: string;
  lastName: string;
  phone: string;
  azureUserPrincipalName?: string;
  companyContact: boolean;
}

export const UserService = {
  search: getAxiosFetcher<User[], [params: UserSearchParams]>((params) => [
    api.USER_URL,
    { params },
  ]),

  lookup: LookupService.user,

  inviteUsers: (invitations: Invitation[]) => crudService.post<void>({ invitations }, api.USER_URL),

  me: getAxiosFetcher<User, []>(() => [`${api.USER_URL}/me`]),

  updateMe: (body: UpdateProfileBody) => crudService.put<User>(body, `${api.USER_URL}/me`),

  changePassword: (body: UpdatePasswordBody) =>
    crudService.put<void>(body, `${api.USER_URL}/me/password`),

  findById: getAxiosFetcher<User, [number]>((id) => [`${api.USER_URL}/${id}`]),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<void>(operations, `${api.USER_URL}/${id}?version=${version}`),

  delete: (id: number) => crudService.delete<void>(`${api.USER_URL}/${id}`),

  invite: (id: number) => crudService.post<void>({}, `${api.USER_URL}/${id}/invite`),
};
