import type { Enum } from './enum';

export interface Permission extends Enum<Permissions> {
  description: string;
  permissionSubcategory: string;
}

export interface PermissionSubcategory extends Enum {
  description: string;
  permissionCategory: string;
}

export enum Permissions {
  INVITE_USER = 'INVITE_USER',
  UPDATE_USER = 'UPDATE_USER',
  READ_USER = 'READ_USER',
  CREATE_INSURANCE = 'CREATE_INSURANCE',
  UPDATE_INSURANCE = 'UPDATE_INSURANCE',
  READ_INSURANCE = 'READ_INSURANCE',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
  READ_SERVICE = 'READ_SERVICE',
  CREATE_SERVICE_REGION = 'CREATE_SERVICE_REGION',
  UPDATE_SERVICE_REGION = 'UPDATE_SERVICE_REGION',
  READ_SERVICE_REGION = 'READ_SERVICE_REGION',
  CREATE_JOB = 'CREATE_JOB',
  UPDATE_JOB = 'UPDATE_JOB',
  READ_JOB = 'READ_JOB',
  APPROVE_JOB = 'APPROVE_JOB',
  CREATE_WORK_ORDER = 'CREATE_WORK_ORDER',
  UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER',
  READ_WORK_ORDER = 'READ_WORK_ORDER',
  ACCEPT_WORK_ORDER = 'ACCEPT_WORK_ORDER',
  RESOLVE_WORK_ORDER = 'RESOLVE_WORK_ORDER',
  ASSIGN_WORK_ORDER_VENDOR = 'ASSIGN_WORK_ORDER_VENDOR',
  CREATE_VISIT = 'CREATE_VISIT',
  UPDATE_VISIT = 'UPDATE_VISIT',
  READ_VISIT = 'READ_VISIT',
  READ_WORK_ORDER_ACTIVITY = 'READ_WORK_ORDER_ACTIVITY',
  CREATE_BID = 'CREATE_BID',
  UPDATE_BID = 'UPDATE_BID',
  READ_BID = 'READ_BID',
  ACCEPT_BID = 'ACCEPT_BID',
}
