import DOMPurify from 'dompurify';
import type { FC, HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  html: string | undefined;
};

const RawHtml: FC<Props> = ({ html, ...divProps }) => {
  const __html = DOMPurify.sanitize(html ?? '');

  return <div {...divProps} dangerouslySetInnerHTML={{ __html }} />;
};

export default RawHtml;
