import useStorageEvent from '@/hooks/useStorageEvent';
import { getUserPermissions } from '@/utils/auth';
import { useState } from 'react';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState(getUserPermissions() ?? []);

  useStorageEvent('permissions', () => {
    setPermissions(getUserPermissions() ?? []);
  });

  return { permissions };
};
