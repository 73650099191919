import type { Permissions } from '@/domain/permission';
import type { PartyRoleType } from '@/domain/person';
import type { User } from '@/domain/user';
import { EnvironmentService } from '@/services/environment';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';

const triggerLocalStorageEvent = (key: string) => {
  const ev = new StorageEvent('storage', { key });
  window.dispatchEvent(ev);
};

const redirectToAuthClient = () => {
  const { origin, pathname, search, hash } = window.location;

  const params = new URLSearchParams({
    // Base64-encoded to avoid conflicts with query params after redirect
    returnTo: btoa(`${pathname}${search}${hash}`),
    callbackUrl: `${origin}/auth/callback`,
  });
  const path = `/login?${params}`;

  EnvironmentService.getAuthClientUrl().then((authClientUrl) => {
    window.location.href = authClientUrl + path;
  });
};

export function logout(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  redirectToAuthClient();
}

export function setAccessToken(token: string): void {
  localStorage.setItem('access_token', token);
  triggerLocalStorageEvent('access_token');
}

export function setInviteToken(token: string): void {
  localStorage.setItem('invite_token', token);
}

export function setRefreshToken(token: string): void {
  localStorage.setItem('refresh_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const getInviteToken = () => localStorage.getItem('invite_token');

export const loggedIn = () => {
  const token = getRefreshToken();
  return !!token;
};

export type ParsedJwt = {
  exp: number;
  organizationRoleTypes: PartyRoleType[];
  permissions: Permissions[];
  orgId: number;
  userId: number;
  loginId: number;
  trackId: number;
};

export const parseJwt = memoize(
  (token: string | null): null | ParsedJwt => {
    if (!token) {
      return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    return JSON.parse(jsonPayload);
  },
  (token) => token,
);

export const getOrganizationId = (): number | undefined => {
  const token = getAccessToken();
  return parseJwt(token)?.orgId;
};

export const getOrganizationRoles = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    const parsed = parseJwt(accessToken);

    return parsed?.organizationRoleTypes || [];
  }
  return [];
};

export const hasOrganizationPermissions = (organizationPermission: PartyRoleType[]) => {
  const organizationRoleTypes = getOrganizationRoles();
  return organizationPermission.some((organizationRoleType) =>
    organizationRoleTypes.includes(organizationRoleType),
  );
};

export const setUserPermissions = (permissions: Permissions[]) => {
  localStorage.setItem('permissions', JSON.stringify(permissions));
  triggerLocalStorageEvent('permissions');
};

export const getUserPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions') || '[]';
    const permissions: Permissions[] = JSON.parse(value);

    return permissions;
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions).sort();
};

export const hasPermissions = (
  key: Permissions | Permissions[],
  permissions = getUserPermissions() ?? [],
) =>
  typeof key === 'string'
    ? !!permissions?.includes(key)
    : !!key?.every((permission) => permissions?.includes(permission));

export const hasOnePermissionOf = (rolePermissions: Permissions[]) => {
  return isEmpty(rolePermissions) || rolePermissions.some((p) => hasPermissions(p));
};

export const getUserOrgId = memoize(
  () => {
    const accessToken = getAccessToken();
    const parsed = parseJwt(accessToken);

    return parsed?.orgId ?? null;
  },
  () => getAccessToken(),
);
