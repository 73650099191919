export enum api {
  PROPERTIES_URL = '/properties',
  VENDOR_URL = '/vendors',
  VENDOR_SERVICES = '/vendor-services',
  NOTE_URL = '/notes',
  FILE_URL = '/files',
  ENUMERATION_URL = '/enumerations',
  AUTH_URL = '/auth',
  USER_URL = '/users',
  PARTIES_URL = '/parties',
  DASHBOARDS_URL = '/dashboards',
  FIXED_ASSETS_URL = '/fixed-assets',
  INVOICES = '/invoices',
  WORK_ORDERS_URL = '/work-orders',
  SERVICES_URL = '/services',
  BIDS_URL = '/bids',
  JOB_URL = '/jobs',
  SAVED_FILTERS_URL = '/saved-filters',
  SERVICES_CATEGORIES = '/services/categories',
  SERVICES_SUBCATEGORIES = '/services/subcategories',
  SERVICES_REGIONS = '/service-regions',
  VENDOR_INSURANCE_POLICY_URL = '/insurance-policies',
  VENDOR_INSURANCE_COVERAGE_URL = '/vendor-insurance-coverages',
  USER_ROLE_TYPES_URL = '/user-role-types',
  VISITS_URL = '/visits',
  PERMISSIONS_URL = '/permissions',
  TOUCHES_URL = '/touches',
}

export enum EnumType {
  JOB_STATUS = 'job-status',
  WORK_ORDER_PARTICIPANT_TYPE = 'work-order-participant-type',
  STATE = 'state',
  JOB_TYPE = 'job-type',
  USER_STATUS = 'user-status',
  WORK_ORDER_STATUS = 'work-order-status',
  WORK_ORDER_PRIORITY = 'work-order-priority',
  PERMISSION_CATEGORY = 'permission-category',
  PERMISSION_SUBCATEGORY = 'permission-subcategory',
  BID_STATUS = 'bid-status',
  WORK_ORDER_OCCUPANCY_STATUS = 'work-order-occupancy-status',
  INVOICE_STATUS = 'invoice-status',
  WORK_ORDER_SCOPE_ITEM_STATUS = 'work-order-scope-item-status',
}
