import { PageLoading } from '@/components/Loader';
import type { Permissions } from '@/domain/permission';
import { useMe } from '@/hooks/services/users';
import { useIsLoggedIn } from '@/hooks/useIsLoggedIn';
import { usePermissions } from '@/hooks/usePermissions';
import useStorageEvent from '@/hooks/useStorageEvent';
import ErrorBoundary from '@/pages/ErrorBoundary';
import type { PropifyRoute } from '@/routes';
import { routes, unauthorizedRoute } from '@/routes';
import { SentryService } from '@/services/sentry';
import { getPermissionsFromUser, hasPermissions, logout, setUserPermissions } from '@/utils/auth';
import { Suspense, useEffect, useMemo, useState } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router';

const MAXIMUM_ME_RETRIES = 1;

const filterRoutes: (routesList: PropifyRoute[], permissions: Permissions[]) => PropifyRoute[] = (
  routesList,
  permissions,
) =>
  routesList
    .map((route) =>
      !route.permissions || hasPermissions(route.permissions, permissions)
        ? route
        : { ...unauthorizedRoute, path: route.path, routes: route.routes },
    )
    .map((route) => ({
      ...route,
      routes: route.routes ? filterRoutes(route.routes, permissions) : undefined,
    }));

const generateRoutes: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) =>
  routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component {...route.props} />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRoutes(route.routes) : undefined,
    ErrorBoundary,
  }));

export const usePermissionsRouter = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { permissions } = usePermissions();
  const [, setMeRetryCount] = useState(0);
  const [accessTokenInStorage, setAccessTokenInStorage] = useState(
    localStorage.getItem('access_token') || '',
  );

  const {
    data: currentUser,
    error,
    mutate: refetchUser,
  } = useMe({
    disabled: !isLoggedIn,
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    setMeRetryCount((meRetryCount) => {
      if (meRetryCount >= MAXIMUM_ME_RETRIES) {
        logout();
        return 0;
      }
      return meRetryCount + 1;
    });
  }, [error]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    setUserPermissions(getPermissionsFromUser(currentUser));
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    refetchUser();
  }, [accessTokenInStorage]);

  const updateStorageValues = () => {
    const newAccessToken = localStorage.getItem('access_token') || '';
    setAccessTokenInStorage(newAccessToken);
  };
  useStorageEvent('access_token', updateStorageValues);

  const permissionsRouter = useMemo(
    () =>
      SentryService.sentryCreateBrowserRouter([
        {
          path: '/',
          element: <Outlet />,
          children: generateRoutes(filterRoutes(routes, isLoggedIn ? permissions : [])),
          ErrorBoundary,
        },
      ]),
    [isLoggedIn, permissions],
  );

  return { permissionsRouter, isLoading: isLoggedIn && !currentUser };
};
